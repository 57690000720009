<template>
  <div class="chat-page">
    <div class="chat-content" ref="chatContainer">
      <div
        class="ishistory"
        style="padding-bottom: 20px"
        v-if="ChatListMessagesSize"
      >
        <div class="ishistory-artificial">人工客服已接入</div>
      </div>
      <div
        class="chat-window"
        v-for="message in ChatListMessages"
        :key="message.id"
      >
        <div class="chat-window-l" v-if="message.sender != 'user'">
          <div class="chat-window-l-time">
            {{ message.createTime ? countTime(message.createTime) : "" }}
          </div>
          <div class="xj-h5-image">
            <img
              src="./assets/chart_server.svg"
              alt="img"
              class="oMpzWEaqA0wrofY2KhXt"
            />
          </div>
          <div class="message receiver-message" v-html="message.text"></div>
          <!-- <div class="xj-h5-view nsymsLWM2IPo08lCtOZY false"><div class="xj-h5-view AlfAkx1uMQ89kZ9DsKDJ"><div class="xj-h5-view UA6und2wy6sN2vpqPYrF"></div><div class="dnfP7RanzO4HSC_8NziJ"><p>【已转人工】您好，您的最高学历是？</p></div></div></div> -->
        </div>

        <div class="chat-window-r" v-else>
          <div class="chat-window-r-time">
            {{ countTime(message.createTime) }}
          </div>

          <div class="message-box">
            <div class="message user-message" v-html="message.text"></div>
            <div class="messageSee">{{ message.see ? "未读" : "已读" }}</div>
          </div>

          <div
            class="xj-h5-image SDSGZ65uh3WgKO8jNqQf"
            style="overflow: hidden"
          >
            <img
              src="./assets/chart_user.svg"
              alt="img"
              style="width: 100%; height: 100%; object-fit: unset"
            />
          </div>
        </div>
      </div>
      <div class="ishistory" v-if="!ChatListMessagesSize">
        <div class="ishistory-chat">
          <div class="ishistory-chat-line"></div>
          <div class="ishistory-chat-txt">以上是历史消息</div>
          <div class="ishistory-chat-line"></div>
        </div>
        <div class="ishistory-artificial">人工客服已接入</div>
      </div>
      <div v-if="!ChatListMessagesSize">
        <div
          class="chat-window"
          v-for="(message, index) in newChatListMessages"
          :key="index"
        >
          <div class="chat-window-l" v-if="message.sender != 'user'">
            <div class="chat-window-l-time">
              {{ countTime(message.createTime) }}
            </div>
            <div
              class="xj-h5-image oMpzWEaqA0wrofY2KhXt"
              style="overflow: hidden"
            >
              <img
                src="./assets/chart_server.svg"
                alt="img"
                style="width: 100%; height: 100%; object-fit: unset"
              />
            </div>
            <div class="message receiver-message" v-html="message.text"></div>
            <!-- <div class="xj-h5-view nsymsLWM2IPo08lCtOZY false"><div class="xj-h5-view AlfAkx1uMQ89kZ9DsKDJ"><div class="xj-h5-view UA6und2wy6sN2vpqPYrF"></div><div class="dnfP7RanzO4HSC_8NziJ"><p>【已转人工】您好，您的最高学历是？</p></div></div></div> -->
          </div>
          <div class="chat-window-r" v-else>
            <div class="chat-window-r-time">
              {{ countTime(message.createTime) }}
            </div>
            <div class="message-box">
              <div class="message user-message" v-html="message.text"></div>
              <div class="messageSee">{{ message.see ? "未读" : "已读" }}</div>
            </div>
            <div class="xj-h5-image">
              <img
                src="./assets/chart_user.svg"
                alt="img"
                class="SDSGZ65uh3WgKO8jNqQf"
              />
            </div>
          </div>
        </div>
        <!-- <div :style="'height:' + clientHeight + 'px'"></div> -->
      </div>
    </div>
    <div class="chat-fooler">
      <div class="message-input">
        <i
          :class="
            !isShow ? 'el-icon-circle-plus-outline' : 'el-icon-circle-close'
          "
          @click="newshow"
        ></i>
        <!--  v-cover  @focus="scrollToInput"  @focus="MessageChange"-->
        <input
          id="ainput"
          v-model="newMessage"
          @input="filterInput"
          @keyup.enter="userSendMessage"
          placeholder="说点什么..."
          :always-embed="true"
          :adjust-position="true"
          cursor-spacing="30"
        />
        <button @click="userSendMessage">发送</button>
      </div>
      <div class="sumit-file" v-if="isShow">
        <div class="sumit-file-item" @click="showModal = true">
          <i class="el-icon-s-order"></i>
          <span>智能咨询页声明</span>
        </div>
      </div>
    </div>
    <custom-modal :show-modal="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import axios from "axios";
import CustomModal from "./components/CustomModal/index.vue";

// import VueScrollTo from 'vue-scrollto';
export default {
  name: "index",
  components: {
    CustomModal,
  },
  data() {
    return {
      messages: [], // 存储聊天消息

      newMessage: "", // 输入框中的新消息
      pollingInterval: null, // 轮询定时器
      messageQueue: [], // 存储待发送的消息
      newProbeData: [],
      ChatList: [],
      ChatListMessages: [],

      timer: null, // 定时器
      oneChattimer: null,
      jqrSubmitTimer: null,
      userInput: "",
      dx: "XMDX001",
      hm: "32",
      isShow: false,
      currentPage: 1,
      pageSize: 10,
      //    faceList: emojiArr,
      tabIndex: 0, //表情包的类型索引
      show: false,
      ishistory: false,
      newChatListMessages: [],
      oldChatListMessages: [],
      sendToServerData: [],
      ChatListMessagesSize: true,
      newTime: "2023-08-25 16:11:46",
      probepop: null,
      showModal: false,
      clientHeight: 0,
      intervalId: null,
      shouldPrint: false,
      robotUrl: "",
      dataUrl: "",
      chatNo: "",
      timerIds: [],
      sendtflage: false,
      tflage: false,
    };
  },
  mounted() {
    // const ua = navigator.userAgent;
    // const IOS = /iphone|ipad|ipod/.test(ua);
    // const input = document.querySelector("#ainput");
    var input = document.getElementById("ainput");
    input.addEventListener("focus", () => {
      this.isShow = false;
      setTimeout(() => {
        input.scrollIntoView(false);
        this.scrollToBottom();
      }, 200);
    });

    this.dataUrl = document.referrer;
    console.log(this.dataUrl);
   
    this.robotUrl = window.location.href;
    console.log(this.robotUrl);

    let params = new URLSearchParams(window.location.search);
    console.log("param", params);
    this.dx = params.get("t");
    this.hm = params.get("hm");
    console.log("myparam", this.dx, this.hm);

    this.createChat();

    // this.executeTasks();
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    // this.stopPolling();
  },
  computed: {
    CountChatListMessages() {
      if (!this.ChatListMessagesSize) {
        return this.oldChatListMessages;
      } else {
        return this.ChatListMessages;
      }
    },
    countTime() {
      return function (time) {
        // console.log("time", time);
        const dateTimeParts = time.split(" ");
        return dateTimeParts[1] || "";
      };
    },
  },
  methods: {
    newshow() {
      this.isShow = !this.isShow;
      // document.body.addEventListener("focusin", (e) => {
      //   console.log("大撒旦发生发射点范德萨发");
      //   var timer1 = setInterval(() => {
      //     document.body.scrollTop = document.body.scrollHeight;
      //     this.scrollToBottom();
      //     clearInterval(timer1);
      //   }, 300);
      // });
    },
    timestampToTime() {
      let date = new Date(); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // countTime(time) {
    //   const dateTimeParts = time.split(" ");
    //   const dateTimeWithoutSeconds =
    //     dateTimeParts[0] + " " + dateTimeParts[1].substring(0, 5);
    //   return dateTimeWithoutSeconds;
    // },
    // 建立聊天接口
    createChat() {
      axios.post("https://backstage.gzyl.cc/api/robot/create", {}).then((res) => {
        let {
          data: { data, code },
        } = res;
        console.log(code, data);
        if (code == 200) {
          localStorage.setItem("chatNo", data.chatNo);
          // this.sendMessages();
          //   this.delclear();
          this.chatNo = data.chatNo;
          this.ChatListMessagesSize = data.firstFlag;
          console.log("this.ChatListMessagesSize", this.ChatListMessagesSize);
          //   this.oneGetChatList();
          this.getProbe();
        }
      });
    },
    // 第一次获取接口列表
    oneGetChatList() {
      axios
        .get("https://backstage.gzyl.cc/api/robot/list", {
          params: {
            // chatNo: getChatNo,
            pageNo: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          console.log(res);
          let {
            data: { data, code },
          } = res;
          console.log(code, data);
          if (code == 200) {
            // this.noOneGetChatList(data);
            let arrs = [];
            data.forEach((e) => {
              if (e.userContent) {
                arrs.push({
                  text: e.userContent,
                  sender: "user",
                  id: e.id,
                  createTime: e.createTime,
                });
              } else {
                arrs.push({
                  text: e.robotContent,
                  sender: "",
                  id: e.id,
                  createTime: e.createTime,
                });
              }
            });

            // if (!this.ChatListMessagesSize) {
            //   console.log("22");
            //   this.newChatListMessages = arrs;
            //
            // } else {

            // this.ChatListMessages = arrs;
            this.ChatList = arrs;
            // this.clientHeight = document.documentElement.clientHeight - 220;
            // console.log("clientHeight", this.clientHeight);
            if (this.ChatListMessagesSize) {
              this.oneChatSendMessages();
            } else {
              console.log("设置");
              this.ChatListMessages = arrs;
              //   this.getProbe();
              this.executeTasks();
              this.scrollToBottom();
            }
          }
        });
    },

    // 删除接口列表数据
    delclear() {
      axios
        .delete("https://backstage.gzyl.cc/api/robot/clear", {
          params: {},
        })
        .then((res) => {
          console.log("删除成功", res);
          this.oneGetChatList();
        });
    },
    // 获取探头数据
    getProbe() {
      axios
        .get("https://backstage.gzyl.cc/api/probe/robot", {
          params: {
            code: this.dx,
          },
        })
        .then((res) => {
          console.log("res", res);
          let {
            data: { code, data },
          } = res;
          document.title = data.title;

          setTimeout(() => {
              let createTime = this.timestampToTime();
              console.log("createTime",createTime)
            if (!this.ChatListMessagesSize) {
              this.newChatListMessages.push({
                text: data.greetingContent,
                createTime,
              });
            } else {
              this.ChatListMessages.push({
                text: data.greetingContent,
                createTime,
              });
            }
            this.oneGetChatList();
            this.sendToServer(data.greetingContent, 2);
            this.scrollToBottom();
          }, 900);

          this.newProbeData = data.probeInfoVOS;
          this.tflage = data.tflag;

          // setTimeout(() => {
          //   this.executeTasks();

          // }, this.newProbeData[0].sec * 1000);
        });
    },
    scrollToInput() {
      this.isShow = false;
      //  document.body.style.transform = 'translateY(-100px)';
      //     VueScrollTo.scrollTo('input', 600, { offset: -400 }); // 调整offset以适应你的需求
      // this.scrollToBottom();
    },
    // 初始化停止
    stopSending() {
      clearTimeout(this.timer);
    },
    executeTasks() {
      if (this.newProbeData.length === 0) {
        return;
      }
      // 判断this.newProbeData 数组中的 count 是否全部为 0
      if (this.newProbeData.every((item) => item.count === 0)) {
        // this.stopSending();
        return;
      }
      if (this.tflage) {
        this.stopAllTimers();
        return;
      }

      this.newProbeData.forEach((e, i) => {
        this.startTimer(e);
      });
    },
    startTimer(e) {
      if (e.count > 0) {
        const timerId = setInterval(() => {
          e.count -= 1;
          let random =
            e.contents[Math.floor(Math.random() * e.contents.length)];
          console.log(random);
          let createTime = this.timestampToTime();
          if (!this.ChatListMessagesSize) {
            this.newChatListMessages.push({
              text: random,
              createTime,
            });
          } else {
            this.ChatListMessages.push({
              text: random,
              createTime,
            });
          }
          this.sendToServer(random, 2);
          this.scrollToBottom();
          if (e.count == 0) {
            clearInterval(timerId);
          }
        }, e.sec * 1000);
        this.timerIds.push(timerId); // 将定时器ID存储到数组中
      }
    },
    stopAllTimers() {
      this.timerIds.forEach((timerId) => {
        clearInterval(timerId); // 停止定时器
      });
      this.timerIds.length = 0; // 清空定时器ID数组
    },
    // 探头输出
    async executeContent(istype) {
    //   console.log("istype", istype);
      let promises = [];
      //   console.log("新", this.newProbeData);
      // this.shouldPrint = true; // 添加一个标志位控制是否继续打印
      let setTime;
      if (istype) {
        setTime = 1000;
        // console.log("wfwef");
      } else {
        setTime = 0;
        // console.log("fdsa");
      }
      for (let i = 0; i < this.newProbeData.length; i++) {
        const promise = new Promise((resolve, reject) => {
          const intervalId = setInterval(() => {
            // console.log("intervalId", this.newProbeData[i].sec * 1000, istype);

            if (this.newProbeData[i].count > 0 && this.shouldPrint) {
              // 随机选择一个内容并输出
              const randomIndex = Math.floor(
                Math.random() * this.newProbeData[i].contents.length
              );

              let createTime = this.timestampToTime();
              if (!this.ChatListMessagesSize) {
                this.newChatListMessages.push({
                  text: this.newProbeData[i].contents[randomIndex],
                  createTime,
                });
              } else {
                this.ChatListMessages.push({
                  text: this.newProbeData[i].contents[randomIndex],
                  createTime,
                });
              }
              // 执行次数减一
              this.newProbeData[i].count -= 1;
              // this.sendToServer(this.newProbeData[i].contents[randomIndex], 2);
              this.scrollToBottom();

              //   console.log(this.newProbeData[i].contents[randomIndex]);

              // 检查是否达到0，如果是则停止执行
              if (this.newProbeData[i].count == 0) {
                clearInterval(intervalId);
                resolve(istype);
                
              }
            
            } else {
        
              clearInterval(intervalId);
              if (istype) {
                resolve(istype);
              }

              // if (istype) {
              //   clearInterval(intervalId);
              //   resolve();
              // } else {
              //   clearInterval(intervalId);
              // }
            }
          }, this.newProbeData[i].sec * setTime);
        });

        promises.push(promise);
      }

      // 等待所有Promise完成
      await Promise.all(promises);
      // console.log("alls",alls)
    },
    // 遍历循环时间发送
    sendMessages(index = 0) {
    //   console.log("初始化发送");
      if (this.newProbeData.length === 0) {
        return;
      }
      // 判断this.newProbeData 数组中的 count 是否全部为 0
      if (this.newProbeData.every((item) => item.count === 0)) {
        this.stopSending();
        return;
      }
      var num = this.newProbeData.length;

      const message = this.newProbeData[index];

      if (message.count > 0) {
        this.timer = setTimeout(() => {
          // 在数组中选择其中一个的随机数
          let random =
            message.contents[
              Math.floor(Math.random() * message.contents.length)
            ];

          // const message = this.newProbeData.shift();
        //   console.log("探头显示", random);
          if (this.ChatListMessagesSize) {
            this.newChatListMessages.push({ text: random });
          } else {
            this.ChatListMessages.push({ text: random });
          }
          this.sendToServer(random, 2);
          this.scrollToBottom();
          message.count -= 1;
          if (index == num - 1) {
            index = 0;
          } else {
            index += 1;
          }
        //   console.log("index", index, num);
          this.sendMessages(index);
        }, message.sec * 1000);
      } else {
        if (index == num - 1) {
          index = 0;
        } else {
          index += 1;
        }
        // console.log("index", index, num);
        this.sendMessages(index);
      }
    },
    // 输出接口列表数据停止
    oneChatStopSending() {
      clearTimeout(this.oneChattimer);
    },
    // 输出接口列表数据
    oneChatSendMessages() {
      if (this.ChatList.length === 0) {
        this.executeTasks();
        return;
      }

      this.oneChattimer = setTimeout(() => {
        // var num = this.ChatList.length;

        const message = this.ChatList.shift();

        this.ChatListMessages.push({
          text: message.text,
          id: message.id,
          createTime: message.createTime,
        });

        // if (num == 1) {
        //   this.getProbe();
        // }

        this.scrollToBottom();

        this.oneChatSendMessages();
      }, 800);
    },

    // 用户发送
    userSendMessage() {
      if (this.newMessage.trim() !== "") {
        this.stopSending();
        // clearInterval(this.intervalId);

        this.stopAllTimers();

        let createTime = this.timestampToTime();
        console.log("createTime ", createTime);
        // 添加用户发送的新消息
        if (!this.ChatListMessagesSize) {
          this.newChatListMessages.push({
            sender: "user",
            text: this.newMessage,
            see: true,
            createTime,
          });
        } else {
          this.ChatListMessages.push({
            sender: "user",
            text: this.newMessage,
            see: true,
            createTime,
          });
        }
        this.scrollToBottom();
        this.sendToServer(this.newMessage, 1);

        this.newMessage = "";
      }
    },
    // 发送返回数据
    async sendToServer(content, type) {
    //   console.log("message", content, type);

      let res = await axios.post("https://backstage.gzyl.cc/api/robot/send", {
        chatNo: this.chatNo,
        code: this.dx,
        content,
        type,
        dataUrl: this.dataUrl,
        robotUrl: this.robotUrl,
      });

      let {
        data: { data, code },
      } = res;

      if (code == 200) {
        
        this.sendToServerData = [...this.sendToServerData, ...data.robotChats];
        if (!this.sendtflage) {
          this.sendtflage = data.tflag;
        }
        if (this.sendtflage) {
          this.stopAllTimers();
        }
        if (type == 1) {
          setTimeout(() => {
            if (!this.ChatListMessagesSize) {
              this.$set(
                this.newChatListMessages[this.newChatListMessages.length - 1],
                "see",
                false
              );
            } else {
              this.$set(
                this.ChatListMessages[this.ChatListMessages.length - 1],
                "see",
                false
              );
            }

            this.sendToMessage();
          }, 800);
        }
        this.scrollToBottom();
      }
    },
    // user发送后回复
    sendToMessage() {
      if (this.sendToServerData.length === 0) {
        
        // this.isjqrSubmit();
        if (!this.sendtflage) this.executeTasks();
        return;
      }
      const message = this.sendToServerData.shift();
      setTimeout(() => {
        var num = this.sendToServerData.length;
       
        let createTime = this.timestampToTime();

        if (!this.ChatListMessagesSize) {
          this.newChatListMessages.push({
            text: message.robotContent,
            id: message.id,
            createTime,
          });
        } else {
          this.ChatListMessages.push({
            text: message.robotContent,
            id: message.id,
            createTime,
          });
        }
        this.scrollToBottom();

        // if (num == 1) {
        //   console.log("机器人回复", message.robotContent);
        //   // this.scrollToBottom();

        //   // this.isjqrSubmit();
        //   // this.sendMessages();
        //   this.executeContent();
        // }

        this.sendToMessage();
      }, message.sec * 1000);
    },
    MessageChange() {
    //   console.log("触发");

      //   window.addEventListener("resize", () => {
      //     if (document.activeElement.tagName == "INPUT") {
      //       window.setTimeout(() => {
      //         this.scrollToBottom();

      //         document.activeElement.scrollIntoViewIfNeeded();
      //       }, 100);
      //     }
      //   });
      this.isShow = false;
      // var timer1 = setInterval(() => {
      //   document.body.scrollTop = document.body.scrollHeight;
      //   this.scrollToBottom();
      //   clearInterval(timer1);
      // }, 100);
      // 解决 ios 键盘弹出后，键盘遮挡输入框的问题
      setTimeout(() => {
        textareaObj.dom.scrollIntoView(false);
        setTimeout(() => {
          document.documentElement.scrollTop =
            document.documentElement.scrollHeight;
        }, 0);
      }, 0);
    },
    blur() {
      //失去焦点的时候，进行触发
      var u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        //安卓手机
        this.$refs.input.scrollIntoView(false);
      } else {
        window.scroll(0, 0); //苹果
      }
    },
    filterInput() {
      // 这里可以实现自定义的过滤逻辑
      const dirtyWords = ["傻逼", "你妈逼"]; // 你需要定义一组脏话词汇

      // 使用正则表达式或其他逻辑检查输入文本
      const cleanText = this.newMessage.replace(
        new RegExp(`(${dirtyWords.join("|")})`, "gi"),
        "***"
      );

      this.newMessage = cleanText;
    },
    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        // 使用 $refs 获取 chatContainer DOM 元素
        const chatContainer = this.$refs.chatContainer;

        // 使用 scrollTop 和 scrollHeight 调整滚动位置
        chatContainer.scrollTop = chatContainer.scrollHeight + 60;
        // console.log("高度", chatContainer.scrollTop);
      });
    },
  },
};
</script>

<style>
body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

.chat-fooler {
  position: fixed;
  /* position: sticky; */
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  /* height:60px; */
  background: #fff;
}
.chat-page {
  /* width:100vw; */
  /* position:relative; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* width:100vw; */
  /* background-color: #f6f8f9; */
  background-color: pink;
  /* height: 100%; */
  /* overflow-y: scroll; */
}
.chat-content {
  /* position:absolute; */

  /* width:100vw; */
  background-color: rgb(246, 248, 249);
  /* background-color: red; */

  /* padding-bottom: 0.5333rem; */
  /* flex: 1; */
  flex-grow: 1;
  padding: 0.2632rem 0;
  /* overflow-scrolling: touch; */
  /* -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      flex-grow: 1;
      flex-shrink: 1; */
  overflow: auto;
  /* overflow-y: scroll; */
  padding-bottom: 1.8421rem;
  scrollbar-width: 0; /* Firefox */
  /* -ms-overflow-style: none; IE and Edge */

  /* 滚动条滑块 */
}

::-webkit-scrollbar {
  width: 1px;
  /* height:16px; */
  background-color: transparent;
}

/*  */
.chat-window {
  /* flex: 1; */
  padding: .3947rem .2632rem;
}
.chat-window-l {
  position: relative;
  /* max-width: 90%; */

  align-self: flex-start;
  font-size: 0.4211rem;
  display: flex;
}

.chat-window-l-time {
  font-size: 0.3158rem;
  color: #c0c8d0;
  position: absolute;
  top: -0.3421rem;
  left: 1.4474rem;
}
.chat-window-r-time {
  font-size: 0.3158rem;
  color: #c0c8d0;
  position: absolute;
  top: -0.4737rem;
  right: 1.4474rem;
}

.chat-window-r {
  align-self: flex-end;
  /* max-width: 80%; */
  position: relative;
  font-size: 0.4211rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: row !important;
}
.message-box {
  max-width: 70%;
  margin: 0.0632rem 0.3947rem;
}
.message {
  padding: 0.2105rem 0.2947rem;
  border-radius: 0.2632rem;
  /* max-width: 70%; */
  font-size: 0.3684rem;
  position: relative;
}

.user-message {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}
.user-message::before {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 0.2632rem solid #007bff;
  border-top: 0.2632rem solid transparent;
  border-bottom: 0.2632rem solid transparent;
  position: absolute;
  top: 3px;
  right: -6px;
}
.messageSee {
  text-align: right;
  margin-right: 0.33333rem;
  font-size: 0.26667rem;
  line-height: 0.4211rem;
  color: #a3a3a3;
  margin-top: 0.1316rem;
}

.receiver-message {
  max-width: 70%;
  align-self: flex-start;
  background-color: #fff;
  margin-top: 0.2632rem;
  margin-left: 0.2632rem;
}
.receiver-message::before {
  content: "";
  width: 0px;
  height: 0px;
  border-right: 0.2632rem solid #fff;
  border-top: 0.2632rem solid transparent;
  border-bottom: 0.2632rem solid transparent;
  position: absolute;
  top: 0.0789rem;
  left: -0.1579rem;
}

.message-input {
  box-sizing: border-box;
  /* width: 100%;
    position: fixed; */
  /* bottom: 0; */
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.4632rem 0.3158rem;
  border-top: 0.0263rem solid #ccc;
}

.message-input input {
  flex: 1;
  padding: 0.2805rem 0.3947rem;
  border: none;
  border-radius: 5px;
  background: #dddddd75;
  border: 0;
  border-radius: 10px;
}
.message-input i {
  font-size: 0.7089rem;
  margin-right: 0.2632rem;
}

.message-input button {
  margin-left: 0.2632rem;
  padding: 0.2516rem 0.4332rem;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
.sumit-file {
  padding: 0.7895rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  background: #f6f8f9;
}
.sumit-file-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sumit-file-item i {
  font-size: 0.7368rem;
}
.sumit-file-item span {
  padding-top: 0.2632rem;
  font-size: 0.4211rem;
}

.oMpzWEaqA0wrofY2KhXt {
  width: 0.9211rem;
  height: 0.9211rem;
  border-radius: 50%;
}
.SDSGZ65uh3WgKO8jNqQf {
  width: 0.9211rem;
  height: 0.9211rem;
  border-radius: 50%;
}

.xj-h5-image {
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.xj-h5-view {
  align-items: stretch;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0;
  min-width: 0;
  position: relative;
}
.ishistory {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.3684rem;
  padding-bottom: 0.9rem;
}
.ishistory-chat {
  display: flex;
  align-items: center;
  margin-top: 0.5263rem;
  margin-bottom: 0.3947rem;

  /* flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 10px; */
}
.ishistory-chat-line {
  /* flex: auto; */
  width: 1.8919rem;
  height: 0.027rem;
  background-color: #cfd8e1;
}
.ishistory-chat-txt {
  padding: 0 0.2632rem;
  color: #a2aab3;
  font-size: 0.3243rem;
}
.ishistory-artificial {
  border-radius: 20px;
  background: #c0c8d0;
  padding: 0.1351rem 0.5405rem;
  width: fit-content;
  margin: 0 auto;
  font-size: 0.3243rem;
  line-height: 1.5;
  color: #fff;
}
</style>
