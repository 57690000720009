/*
 * @Author: HJIAN 2297703268@qq.com
 * @Date: 2023-08-31 12:26:58
 * @LastEditors: HJIAN 2297703268@qq.com
 * @LastEditTime: 2023-09-01 09:51:49
 * @FilePath: \robot\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import inputCover from './directive/cover'


import {Icon} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible/flexible.js'
// import "./assets/style/element-variables.scss";
// import "./assets/style/index.scss"
import "normalize.css/normalize.css"; // a modern alternative to CSS resets


Vue.config.productionTip = false
Vue.use(inputCover)
Vue.use(Icon);
new Vue({
  render: h => h(App),
}).$mount('#app')
