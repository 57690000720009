/*
 * @Author: HJIAN 2297703268@qq.com
 * @Date: 2023-09-01 23:32:16
 * @LastEditors: HJIAN 2297703268@qq.com
 * @LastEditTime: 2023-09-01 23:32:26
 * @FilePath: \robot\src\directive\cover.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * v-cover
 * input获取焦点键盘遮挡问题
 */
import {VueConstructor} from 'vue'

const u = navigator.userAgent
  
const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

const inputDirective = {
  
  inserted: function (el) {
    el.onfocus = () => {
      if (isiOS) {
        // 计算偏移量
        let windowHeight =  window.screen.height
        let top = el.getBoundingClientRect().top
        let deviation =  450 - (windowHeight - top - el.clientHeight)
        setTimeout(() => {
          (document.getElementById('content')).style=`transform:translateY(${deviation > 0 ? -deviation : 0}px);transition:0.1s`
        }, 400)
      }
    }
    el.onblur = () => {
      if (isiOS) {
        setTimeout(() => {
          (document.getElementById('content')).style=`transform:translateY(0);transition:0.1s`
        }, 400)
      }
    }
  },
  unbind: function (el) {
    el.onfocus = null
  }

}


export default (app) => {
  //自定义指令
  app.directive('cover', inputDirective)
}

