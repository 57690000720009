<template>
  <transition name="slide-fade">
    <div v-if="showModal" class="custom-modal">
      <div class="modal-content">
        <div class="modal-title">
          <span>智能咨询页声明</span>
          <i class="el-icon-close" @click="closeModal"></i>
        </div>
        <div class="modal-content-c">
          <div>
            <p>尊敬的用户您好，为便于您与本页面医疗机构（以下简称“商家”）进行线上沟通，请您注意以下事项:</p>
            <p>1、为节省您的时间、免收重复输入的困扰，您的搜索词将会在该页面上的输入框中展示，您可以自行选择是否向商家发送该信息，您也可以自行在输入框中修改该信息。若您确认发送，则代表您同意将该信息发送给提供咨询服务的商家；若您没有发送，则您的搜索词信息不会提供给商家。请您在发送之前仔细核查自己的输入信息，避免隐私泄露。</p>
            <p>2、本站仅提供智能咨询页技术服务，您及商家通过本页面所发表的言论仅代表其个人观点，不代表本站立场，请谨慎参阅，本站不承担由此引起的法律责任。</p>
            <p>3、如果您咨询医疗相关问题，由于网络沟通场景无法使提供咨询服务的机器人明确了解您的真实情况，仅能根据您的描述内容为您提供服务引导，请注意任何关于疾病的网络建议都不能替代医生的面对面诊断，请您完成咨询后选择线下就医。为了能够让给您提供更准确的服务引导，请您在线上沟通时务必如实陈述，若因您虚假陈述导致的后果，均与医院、医生及本站无关。</p>
          </div>
          <span>服务声明</span>
          <p>
            本服务是由商家使用本站的咨询软件向您提供，具体的咨询反馈内容由商家提供或由本咨询软件智能答复，商家对其使用本服务所提供的信息内容、数据资料及其运营行为等的真实性、合法性及有效性承担全部责任。
            为实现本服务功能及线下服务之需要，您同意本站将您的咨询内容实时提供给商家，并将商家反馈的信息或本咨询软件智能答复的信息展示在本页面，以实现您与商户或本咨询软件之间进行即时沟通会话的目的。
            在使用咨询服务过程中，当您提供手机号时，为了输入的便捷性，您同意本站从合法获得您手机号的第三方获取您的手机号，以便在您使用便捷输入手机号功能时，为您提供手机号的便捷输入。
            您应对您在使用本服务中所提交的所有信息的合法合规性负责，保证不涉及违法违规内容，不侵犯任何第三方合法权益。且您同意本平台、商家可收集、使用、处理您在使用本服务中自主提交的所有信息，用于为您提供本服务、商家在线咨询答疑及后续为您提供具体商家服务之目的，您亦同意本平台将您所提交的信息匿名化后用于数据分析及本咨询软件的服务能力提升。本服务可能随着法律法规、政策要求及业务发展变化随时做出调整，届时将以实时提供的服务内容为准，本平台也会及时向您同步。
            如您不同意本服务声明的，请您不要继续使用本服务，否则视为您已同意本声明的所有内容并自愿遵守。
            在使用本服务过程中，您有任何疑问的，请通过本站联系。
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CustomModal",
  props: {
    showModal: Boolean,
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.custom-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  z-index: 9999;
}
.modal-title {
  /* width: 100%; */
  /* height: .7895rem; */
  padding: 0.3947rem .2632rem;
  border-bottom: 1px solid #ccc;
  /* position: relative; */
  display: flex;
  align-items: center;
   position: sticky; /*黏性定位*/
   top: 0;
   background: #fff;
      border-top-left-radius: 20px;
  border-top-right-radius: 20px;

}
.modal-title span {
  /* position: absolute;
    left: 50%;
    transform: translateX(-50%); */
  margin: auto;
  font-size: 0.4737rem;
  font-weight: bold;
}
.modal-title i {
  font-size: 0.5263rem;
  /* position: absolute;
    right: .2632rem; */
  text-align: right;
}

.modal-content {    
  background-color: white;
  /* padding: 0.2632rem 0 0.2632rem 0.2632rem; */
  width: 100%;
  /* max-width: 400px; */
      border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);

}
.modal-content-c{
     max-height: 7.8947rem;
  overflow: auto;
  padding: 0 .2632rem;
}
.modal-content-c p{
    text-indent: 1em;
    font-size: .3684rem;
}
.modal-content-c span{
    display: block;
    text-align: center;
    font-size: .4211rem;
    font-weight: bold;

}
/* 渐入动画的样式 */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
